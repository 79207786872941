// import { useEffect } from 'react'
import { useStoryblok } from '../hooks'
import Template from '../templates'

// const NotFoundPage = ({ location }) => {
//   let { story } = useStoryblok(null, location)

//   useEffect(() => {
//     const lang = location.pathname.split('/').some((i) => i === 'en') ? '/en' : ''
//     const in_editor = location.search.includes('_storyblok')
//     if (!in_editor) {
//       window.location.href = location.origin + lang
//     }
//   }, []) // eslint-disable-line

//   let content = null
//   if (story && 'content' in story) content = <Template component={story?.content?.component} blok={story.content} />
//   return content
// }

const NotFoundPage = ({ location, pageContext }) => {
  let { story, ...props } = useStoryblok(null, location, pageContext?.langKey) ?? {}

  const preview_mode = !!location?.search?.includes('_storyblok')

  let content = ''
  if (story && 'content' in story)
    content = (
      <Template preview_mode={!!preview_mode} component={story?.content?.component} blok={story.content} {...props} />
    )
  return content
}

export default NotFoundPage
